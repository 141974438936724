import { Command as CommandPrimitive } from 'cmdk'
import {
  type ComponentProps,
  type KeyboardEvent,
  createContext,
  useCallback,
  useContext,
  useMemo,
  useRef,
  useState
} from 'react'
import { cn } from '#utils/utils.js'
import { Badge } from './Badge'
import {
  Command,
  type CommandCreate,
  CommandEmpty,
  CommandItem,
  CommandList
} from './Command'
import { Icon } from './Icon'
import { inputStyles } from './Input'

export type ComboboxOption = {
  value: string
  label: string
}

type ComboboxType = 'single' | 'multiple'

type ComboboxProps<T extends ComboboxOption> = ComponentProps<
  typeof CommandPrimitive
> & {
  values: T[]
  onValuesChange: (values: T[]) => void
  type?: ComboboxType
}

type ComboboxContextType<T extends ComboboxOption> = {
  values: T[]
  onValueChange: (value: T) => void
  open: boolean
  setOpen: (value: boolean) => void
  inputValue: string
  setInputValue: React.Dispatch<React.SetStateAction<string>>
  ref: React.RefObject<HTMLInputElement | null>
  activeIndex: number
  setActiveIndex: React.Dispatch<React.SetStateAction<number>>
  type: ComboboxType
}

const ComboboxContext = createContext<ComboboxContextType<any> | undefined>(
  undefined
)

const useCombobox = <T extends ComboboxOption>() => {
  const context = useContext(ComboboxContext) as
    | ComboboxContextType<T>
    | undefined
  if (!context)
    throw new Error('useCombobox must be used within a <Combobox />')
  return context
}

const Combobox = <T extends ComboboxOption>({
  values,
  onValuesChange,
  className,
  type = 'multiple',
  ...props
}: ComboboxProps<T>) => {
  const [inputValue, setInputValue] = useState('')
  const [open, setOpen] = useState(false)
  const [activeIndex, setActiveIndex] = useState<number>(-1)

  const inputRef = useRef<HTMLInputElement>(null)

  const onValueChangeHandler = useCallback(
    (value: T) => {
      if (values.map((v) => v.value).includes(value.value)) {
        onValuesChange(values.filter((v) => v.value !== value.value))
      } else {
        onValuesChange(type === 'multiple' ? [...values, value] : [value])
      }
    },
    [values, onValuesChange, type]
  )

  const handleKeyDown = useCallback(
    (e: KeyboardEvent<HTMLDivElement>) => {
      e.stopPropagation()
      const target = inputRef.current
      if (!target) return
      // Early return for empty values array to avoid unnecessary processing
      if (values.length === 0) {
        if (e.key === 'Enter') {
          setOpen(true)
        } else if (e.key === 'Escape') {
          setOpen(false)
        }
        return
      }
      // Handle special keys
      switch (e.key) {
        case 'Enter':
          setOpen(true)
          return
        case 'Escape':
          if (activeIndex !== -1) {
            setActiveIndex(-1)
          } else if (open) {
            setOpen(false)
          }
          return
        case 'ArrowLeft':
          if (target.selectionStart === 0) {
            setActiveIndex(
              activeIndex - 1 < 0 ? values.length - 1 : activeIndex - 1
            )
          }
          return
        case 'ArrowRight':
          if (activeIndex !== -1) {
            setActiveIndex((activeIndex + 1) % values.length)
          }
          return
        case 'Backspace':
        case 'Delete':
          if (activeIndex !== -1 && activeIndex < values.length) {
            onValueChangeHandler(values[activeIndex] as T)
            const newIndex =
              values.length <= 1 ? -1 : activeIndex === 0 ? 0 : activeIndex - 1
            setActiveIndex(newIndex)
          } else if (target.selectionStart === 0) {
            onValueChangeHandler(values[values.length - 1] as T)
          }
          return
        default:
          return
      }
    },
    [open, values, activeIndex, onValueChangeHandler]
  )

  return (
    <ComboboxContext
      value={{
        values,
        onValueChange: onValueChangeHandler,
        open,
        setOpen,
        inputValue,
        setInputValue,
        ref: inputRef,
        activeIndex,
        setActiveIndex,
        type
      }}
    >
      <Command
        shouldFilter={false}
        onKeyDown={handleKeyDown}
        className={cn(
          'overflow-visible bg-transparent flex flex-col relative',
          className
        )}
        {...props}
      />
    </ComboboxContext>
  )
}

const ComboboxTrigger = ({
  className,
  children,
  ...props
}: ComponentProps<'div'>) => {
  const { values, onValueChange, activeIndex } = useCombobox()

  const mousePreventDefault = useCallback((e: React.MouseEvent) => {
    e.preventDefault()
    e.stopPropagation()
  }, [])

  return (
    <div
      className={cn(
        inputStyles(),
        'flex flex-wrap gap-1 h-auto min-h-10',
        activeIndex === -1 && 'focus-within:border-primary',
        className
      )}
      {...props}
    >
      {values.map((item, index) => (
        <Badge
          key={item.value}
          size="sm"
          className={cn(
            'flex items-center gap-1 px-2',
            activeIndex === index && 'ring-2 ring-muted-foreground '
          )}
          variant="secondary"
        >
          <span>{item.label}</span>
          <button
            aria-label={`Remove ${item} option`}
            type="button"
            onMouseDown={mousePreventDefault}
            onClick={() => onValueChange(item)}
          >
            <span className="sr-only">Remove {item.label} option</span>
            <Icon name="x" className="size-3 hover:stroke-destructive" />
          </button>
        </Badge>
      ))}
      {children}
    </div>
  )
}

const ComboboxInput = ({
  className,
  ...props
}: ComponentProps<typeof CommandPrimitive.Input>) => {
  const {
    values,
    setOpen,
    inputValue,
    setInputValue,
    ref: inputRef,
    activeIndex,
    setActiveIndex,
    type
  } = useCombobox()

  const handleValueChange = (value: string) => {
    if (activeIndex !== -1) return
    setInputValue(value)
    props.onValueChange?.(value)
  }

  const hideInput = type === 'single' && values.length

  return (
    <CommandPrimitive.Input
      {...props}
      tabIndex={0}
      ref={inputRef}
      value={inputValue}
      onValueChange={handleValueChange}
      onBlur={() => setOpen(false)}
      onFocus={() => setOpen(true)}
      onClick={() => setActiveIndex(-1)}
      className={cn(
        'ml-2 outline-none placeholder:text-muted-foreground flex-1',
        className,
        activeIndex !== -1 && 'caret-transparent',
        hideInput && 'hidden'
      )}
    />
  )
}

const ComboboxContent = ({
  className,
  children,
  ...props
}: ComponentProps<'div'>) => {
  const { open } = useCombobox()
  return (
    <div
      className={cn('absolute w-full top-full mt-1 z-10', !open && 'hidden')}
      {...props}
    >
      {children}
    </div>
  )
}

const ComboboxList = ({
  className,
  children,
  ...props
}: ComponentProps<typeof CommandPrimitive.List>) => {
  const { inputValue } = useCombobox()

  return (
    <CommandList
      className={cn(
        'p-2 flex flex-col gap-2 rounded-md scrollbar-thin scrollbar-track-transparent transition-colors scrollbar-thumb-muted-foreground dark:scrollbar-thumb-muted scrollbar-thumb-rounded-lg w-full absolute bg-background shadow-md z-10 border border-muted top-0',
        className
      )}
      {...props}
    >
      {children}
      <CommandEmpty>
        <span className="text-muted-foreground">
          {inputValue ? 'No results found' : 'Type to search'}
        </span>
      </CommandEmpty>
    </CommandList>
  )
}

const ComboboxItem = <T extends ComboboxOption>({
  item,
  className,
  children,
  ...props
}: ComponentProps<typeof CommandPrimitive.Item> & { item: T }) => {
  const { values, onValueChange, setInputValue } = useCombobox()

  const mousePreventDefault = useCallback((e: React.MouseEvent) => {
    e.preventDefault()
    e.stopPropagation()
  }, [])

  const isSelected = useMemo(
    () => values.map((v) => v.value).includes(item.value),
    [values, item.value]
  )

  return (
    <CommandItem
      {...props}
      value={item.label}
      onSelect={() => {
        onValueChange(item)
        setInputValue('')
        props.onSelect?.(item.value)
      }}
      className={cn(
        'rounded-md cursor-pointer px-2 py-1 transition-colors flex justify-between ',
        className,
        isSelected && 'opacity-50 cursor-default',
        props.disabled && 'opacity-50 cursor-not-allowed'
      )}
      onMouseDown={mousePreventDefault}
    >
      {children}
      {isSelected && <Icon name="check" className="size-4" />}
    </CommandItem>
  )
}

const ComboboxCreate = ({ ...props }: ComponentProps<typeof CommandCreate>) => {
  const { inputValue, setInputValue, onValueChange } = useCombobox()

  const mousePreventDefault = useCallback((e: React.MouseEvent) => {
    e.preventDefault()
    e.stopPropagation()
  }, [])

  const handleSelect = (value: string) => {
    onValueChange({
      value: value,
      label: value
    })
    setInputValue('')
    props.onSelect?.(value)
  }

  if (!inputValue) return null

  return (
    <CommandItem
      {...props}
      onSelect={handleSelect}
      onMouseDown={mousePreventDefault}
      value={inputValue}
    >
      <Icon name="plus" className="size-4 mr-2" />
      Create "{inputValue}"
    </CommandItem>
  )
}

export {
  Combobox,
  ComboboxTrigger,
  ComboboxInput,
  ComboboxContent,
  ComboboxList,
  ComboboxItem,
  ComboboxCreate
}
