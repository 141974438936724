import type * as React from 'react'
import { DayPicker } from 'react-day-picker'
import { cn } from '#utils/utils.js'
import { buttonVariants } from './Button'
import { Icon } from './Icon'

export type CalendarProps = React.ComponentProps<typeof DayPicker>

function Calendar({
  className,
  classNames,
  showOutsideDays = true,
  ...props
}: CalendarProps) {
  return (
    <DayPicker
      showOutsideDays={showOutsideDays}
      className={cn('p-3', className)}
      classNames={{
        months: 'flex flex-col space-y-4 sm:space-x-4 sm:space-y-0 relative',
        month: 'space-y-4',
        month_caption: 'text-center',
        caption: 'flex justify-center pt-1 relative items-center',
        caption_label: 'text-sm font-medium',
        nav: 'absolute flex items-center justify-between w-full',
        nav_button: cn(
          buttonVariants({ variant: 'outline' }),
          'size-7 bg-transparent p-0 opacity-50 hover:opacity-100'
        ),
        nav_button_previous: '',
        nav_button_next: '',
        table: 'w-full border-collapse space-y-1',
        head_row: 'flex',
        head_cell:
          'text-muted-foreground rounded-md w-9 font-normal text-[0.8rem]',
        row: 'flex w-full mt-2',
        day: 'size-9 text-center text-sm p-0 relative [&:has([aria-selected].day-range-end)]:rounded-r-md [&:has([aria-selected].day-outside)]:bg-secondary [&:has([aria-selected])]:bg-accent-200 first:[&:has([aria-selected])]:rounded-l-md last:[&:has([aria-selected])]:rounded-r-md focus-within:relative focus-within:z-20',
        day_button: cn(
          buttonVariants({ variant: 'ghost' }),
          'size-9 p-0 font-normal aria-selected:opacity-100'
        ),
        day_range_end: 'day-range-end',
        selected: 'bg-base-200 border border-border',
        day_today: 'bg-accent-200',
        day_outside:
          'day-outside text-muted-foreground aria-selected:bg-accent/50 aria-selected:text-muted-foreground',
        disabled: 'text-muted-foreground opacity-50',
        day_range_middle: 'aria-selected:bg-accent-200',
        day_hidden: 'invisible',
        ...classNames
      }}
      components={{
        Chevron: ({ orientation, className }) => {
          if (orientation === 'left') {
            return (
              <Icon name="chevron-left" className={cn('size-3', className)} />
            )
          }
          return (
            <Icon name="chevron-right" className={cn('size-3', className)} />
          )
        }
      }}
      {...props}
    />
  )
}

export { Calendar }
