import * as SwitchPrimitives from '@radix-ui/react-switch'

import { type VariantProps, cva } from 'class-variance-authority'
import { cn } from '#utils/utils.js'

const switchVariants = cva(
  'peer inline-flex shrink-0 cursor-pointer items-center rounded-full border-2 border-transparent transition-colors focus-visible:outline-hidden focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 focus-visible:ring-offset-background disabled:cursor-not-allowed disabled:opacity-50 data-[state=checked]:bg-primary data-[state=unchecked]:bg-input',
  {
    variants: {
      size: {
        default: 'h-6 w-11',
        sm: 'h-5 w-9'
      }
    },
    defaultVariants: {
      size: 'default'
    }
  }
)

type SwitchStyles = VariantProps<typeof switchVariants>

type SwitchProps = React.ComponentProps<typeof SwitchPrimitives.Root> &
  SwitchStyles

const Switch = ({ className, size = 'default', ...props }: SwitchProps) => (
  <SwitchPrimitives.Root
    className={cn(switchVariants({ size }), className)}
    {...props}
  >
    <SwitchPrimitives.Thumb
      className={cn(
        'pointer-events-none block rounded-full bg-background shadow-lg ring-0 transition-transform data-[state=unchecked]:translate-x-0',
        {
          'size-5 data-[state=checked]:translate-x-5': size === 'default',
          'size-4 data-[state=checked]:translate-x-4': size === 'sm'
        }
      )}
    />
  </SwitchPrimitives.Root>
)

export { Switch }
