import {
  FORM_ARRAY_SUFFIX,
  type FormErrors,
  type FormStatus
} from '@repo/ui/components/Forms.js'
import { useCallback, useEffect, useRef, useState } from 'react'
import {
  useActionData,
  useFetcher,
  useNavigation,
  useSubmit
} from 'react-router'
import { z } from 'zod'
import type { ActionResult, SchemaWithIntent } from '~/utils/actions.server'

export const useForm = <T extends SchemaWithIntent>({
  id,
  action,
  method = 'post',
  schema,
  withFetcher = false,
  onSuccess = () => {}
}: {
  id?: string | number
  action?: string
  schema: T
  method?: 'post' | 'get'
  onSuccess?: (data?: ActionResult<unknown>) => void
  withFetcher?: boolean
}) => {
  const intent = schema.shape.intent.value
  const navigation = useNavigation()
  const fetcherKey = id ? `$${intent}_${id}` : intent
  const fetcher = useFetcher<ActionResult<unknown>>({ key: fetcherKey })
  const actionData = useActionData<ActionResult<unknown>>()
  const submit = useSubmit()

  const data = withFetcher ? fetcher.data : actionData
  const submitFn = withFetcher ? fetcher.submit : submit

  const formData = withFetcher ? fetcher.formData : navigation.formData
  const state = withFetcher ? fetcher.state : navigation.state
  const isSubmitting = state !== 'idle' && formData?.get('intent') === intent
  const status = (isSubmitting ? 'submitting' : 'idle') as FormStatus

  const [errors, setErrors] = useState<FormErrors<z.infer<T>> | null>(null)
  const [dirty, setDirty] = useState(false)

  const ref = useRef<HTMLFormElement>(null)

  const getFormProps = () => ({
    method,
    action,
    ref,
    onChange: () => setDirty(true),
    'aria-invalid': !!errors,
    withFetcher,
    fetcher,
    intent,
    errors,
    status
  })

  const reset = useCallback(() => {
    ref.current?.reset()
    setErrors(null)
    setDirty(false)
  }, [])

  const memoizedOnSuccess = useCallback(onSuccess, [])

  useEffect(() => {
    if (!data) return

    if (data.ok) {
      setErrors(null)
      if (!isSubmitting) {
        memoizedOnSuccess(data)
        reset()
      }
    } else {
      setErrors(data.errors)
    }
  }, [data, memoizedOnSuccess, reset, isSubmitting])

  return {
    status,
    fields: getFieldKeys(schema),
    reset,
    getFormProps,
    errors,
    fetcher,
    data,
    state: { dirty },
    submit: submitFn
  }
}

type FieldKeys<T extends SchemaWithIntent> = z.infer<T>
type IsArray<T> = T extends any[] ? true : false
type ArraySuffixLiteral = typeof FORM_ARRAY_SUFFIX
type ArraySuffix<K extends string, T> = IsArray<T> extends true
  ? `${K}${ArraySuffixLiteral}`
  : K

type Fields<T extends SchemaWithIntent> = {
  [K in keyof Required<FieldKeys<T>>]: ArraySuffix<
    K & string,
    Required<FieldKeys<T>>[K]
  >
}

const getFieldKeys = <T extends SchemaWithIntent>(schema: T): Fields<T> => {
  const fieldKeys = schema.keyof().options as (keyof z.infer<T>)[]
  return fieldKeys
    .filter((key) => key !== 'intent')
    .reduce(
      (acc, key) => {
        const isArray = schema.shape[String(key)] instanceof z.ZodArray
        acc[key] = (
          isArray ? `${String(key)}${FORM_ARRAY_SUFFIX}` : key
        ) as Fields<T>[typeof key]
        return acc
      },
      {} as Fields<T>
    )
}
