import {
  type ComponentProps,
  createContext,
  useContext,
  useId,
  useState
} from 'react'
import { type FetcherWithComponents, Form as FormPrimitive } from 'react-router'
import { Button } from './Button.js'
import { ErrorMessage } from './ErrorMessage.js'
import { Input } from './Input.js'
import { Label } from './Label.js'
import { RadioGroup } from './RadioGroup.js'
import { Select } from './Select.js'
import { Textarea } from './Textarea.js'

import { format } from 'date-fns'
import { TZDate } from 'react-day-picker'
import type { z } from 'zod'
import { cn } from '#utils/utils.js'
import { Calendar } from './Calendar.js'
import { Combobox } from './Combobox.js'
import { Icon } from './Icon.js'
import { Popover, PopoverContent, PopoverTrigger } from './Popover.js'
import { Switch } from './Switch.js'

type FormProps<T> = ComponentProps<typeof FormPrimitive> & {
  intent: string
  withFetcher?: boolean
  fetcher: FetcherWithComponents<unknown>
  errors: FormErrors<T> | null
  status: FormStatus
}

export type FormErrors<T> = z.typeToFlattenedError<T>

export type FormStatus = 'idle' | 'submitting'

export const FORM_ARRAY_SUFFIX = '[]'

type FormContextType<T> = Pick<FormProps<T>, 'intent' | 'errors' | 'status'> & {
  id: string
}

const FormContext = createContext<FormContextType<any> | undefined>(undefined)

const useFormContext = () => {
  const context = useContext(FormContext)
  if (!context) throw new Error('useFormContext must be used within a <Form />')
  return context
}

export const Form = <T,>({
  intent,
  withFetcher,
  fetcher,
  status,
  errors,
  children,
  ...props
}: FormProps<T>) => {
  const id = useId()
  const Component = withFetcher ? fetcher.Form : FormPrimitive

  return (
    <FormContext value={{ id, intent, errors, status }}>
      <Component {...props}>
        <input type="hidden" name="intent" value={intent} />
        {children}
      </Component>
    </FormContext>
  )
}

const NameContext = createContext<string | undefined>(undefined)

export const useName = () => {
  const context = useContext(NameContext)
  if (!context) throw new Error('useName must be used within <FormField />')
  return context
}

export const FormField = ({
  name,
  className,
  children,
  ...props
}: ComponentProps<'fieldset'> & { name: string }) => {
  return (
    <NameContext value={name}>
      <fieldset className={cn('space-y-1.5', className)} {...props}>
        {children}
      </fieldset>
    </NameContext>
  )
}

const getFieldId = (formId: string, name: string) => {
  return `${formId}-${name}`
}

export const FormLabel = ({ ...props }: ComponentProps<typeof Label>) => {
  const form = useFormContext()
  const name = useName()
  return (
    <Label {...props} htmlFor={props.htmlFor ?? getFieldId(form.id, name)} />
  )
}

export const FormInput = ({ ...props }: ComponentProps<typeof Input>) => {
  const form = useFormContext()
  const name = useName()
  return <Input {...props} name={name} id={getFieldId(form.id, name)} />
}

export const FormTextarea = ({ ...props }: ComponentProps<typeof Textarea>) => {
  const form = useFormContext()
  const name = useName()
  return <Textarea {...props} name={name} id={getFieldId(form.id, name)} />
}

export const FormSelect = ({ ...props }: ComponentProps<typeof Select>) => {
  const name = useName()
  return <Select {...props} name={name} />
}

export const FormRadioGroup = ({
  ...props
}: ComponentProps<typeof RadioGroup>) => {
  const form = useFormContext()
  const name = useName()
  return <RadioGroup {...props} id={getFieldId(form.id, name)} name={name} />
}

export const FormCombobox = ({
  type,
  ...props
}: ComponentProps<typeof Combobox>) => {
  const form = useFormContext()
  const name = useName()
  return (
    <>
      <input
        type="hidden"
        name={type === 'multiple' ? `${name}${FORM_ARRAY_SUFFIX}` : name}
        value={props.values.map((v) => v.value).join(',')}
      />
      <div>
        <Combobox type={type} {...props} id={getFieldId(form.id, name)} />
      </div>
    </>
  )
}

export function FormDatePicker({
  className,
  defaultValue,
  placeholder = 'Pick a date',
  ...props
}: ComponentProps<typeof Button> & {
  placeholder?: string
  defaultValue?: string
}) {
  const name = useName()
  const [open, setOpen] = useState(false)
  const [selected, setSelected] = useState<Date | null>(
    defaultValue ? new TZDate(defaultValue, 'UTC') : null
  )

  return (
    <>
      <input type="hidden" name={name} value={selected?.toISOString() ?? ''} />
      <Popover open={open} onOpenChange={setOpen}>
        <PopoverTrigger asChild>
          <Button
            variant="outline"
            className={cn(
              'justify-start font-normal w-full h-10',
              !selected && 'text-muted-foreground',
              className
            )}
            {...props}
          >
            <Icon name="calendar" />
            {selected ? format(selected, 'PPP') : <span>{placeholder}</span>}
          </Button>
        </PopoverTrigger>
        <PopoverContent className="w-auto p-0 h-[295px]" side="bottom">
          <Calendar
            mode="single"
            {...props}
            role="dialog"
            selected={selected ?? undefined}
            defaultMonth={selected || new Date()}
            onSelect={(date) => {
              setSelected(date ? new TZDate(date, 'UTC') : null)
              setOpen(false)
              return
            }}
          />
        </PopoverContent>
      </Popover>
    </>
  )
}

export function FormSwitch({
  checked,
  defaultChecked,
  onCheckedChange,
  ...props
}: ComponentProps<typeof Switch>) {
  const name = useName()

  const isControlled = checked !== undefined

  const [internalChecked, setInternalChecked] = useState(
    isControlled ? checked : defaultChecked
  )

  const effectiveChecked = isControlled ? checked : internalChecked

  const handleCheckedChange = (checked: boolean) => {
    setInternalChecked(checked)
    onCheckedChange?.(checked)
  }

  return (
    <>
      <input name={name} value={internalChecked ? 'on' : 'off'} type="hidden" />
      <Switch
        {...props}
        checked={effectiveChecked}
        onCheckedChange={handleCheckedChange}
      />
    </>
  )
}

export const FormCurrencyInput = ({
  defaultValue: defaultValueProp,
  onValueChange,
  icon,
  ...props
}: React.ComponentProps<typeof Input> & {
  onValueChange?: (value: string) => void
}) => {
  const { id } = useFormContext()
  const name = useName()

  const defaultValue = defaultValueProp ? String(defaultValueProp) : ''

  const [value, setValue] = useState<string>(formatWithCommas(defaultValue))

  const [numberValue, setNumberValue] = useState<number>()

  function formatWithCommas(input: string) {
    const parts = input.split('.')

    // Format integer part with commas if it exists
    if (parts[0]) {
      parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',')
    }

    return parts.length > 1 ? parts.join('.') : (parts[0] as string)
  }

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    let input = e.target.value

    // Remove any non-numeric, non-period characters
    input = input.replace(/[^0-9.]/g, '')

    const parts = input.split('.')

    if (parts.length > 2) {
      // If there are multiple periods, keep only the first one and truncate the rest
      input = `${parts[0]}.${parts[1]!.slice(0, 2)}`
    } else if (parts.length === 2 && parts[1]!.length > 2) {
      // If there's one period, truncate the decimal to two digits
      input = `${parts[0]}.${parts[1]!.slice(0, 2)}`
    }

    // Update state with the formatted value
    const formattedValue = formatWithCommas(input)
    setValue(formattedValue)
    setNumberValue(Number.parseFloat(input))
    onValueChange?.(input)
  }

  return (
    <>
      <input name={name} type="hidden" value={numberValue ?? ''} />
      <Input
        id={getFieldId(id, name)}
        icon={icon}
        placeholder="Enter amount"
        name={`${props.name}_formatted_currency`}
        type="text"
        inputMode="numeric"
        value={value}
        onChange={handleChange}
      />
    </>
  )
}

export const FormFieldHint = ({
  className,
  ...props
}: ComponentProps<'small'>) => {
  return (
    <small
      className={cn('block text-muted-foreground text-xs', className)}
      {...props}
    />
  )
}

export const FieldErrors = ({ className, ...props }: ComponentProps<'div'>) => {
  const name = useName()
  const { errors } = useFormContext()
  const fieldErrors = errors?.fieldErrors[name]
  if (!fieldErrors?.length) return null

  return (
    <div className="space-y-2" {...props}>
      {fieldErrors.map((error) => (
        <ErrorMessage key={error}>{error}</ErrorMessage>
      ))}
    </div>
  )
}

export const SubmitButton = ({ ...props }: ComponentProps<typeof Button>) => {
  const { status } = useFormContext()
  return <Button type="submit" loading={status === 'submitting'} {...props} />
}

export const FormErrors = ({ className, ...props }: ComponentProps<'div'>) => {
  const { errors } = useFormContext()
  const formErrors = errors?.formErrors
  if (!formErrors?.length) return null

  return (
    <div className="space-y-2" {...props}>
      {formErrors.map((error) => (
        <ErrorMessage key={error}>{error}</ErrorMessage>
      ))}
    </div>
  )
}

export { RadioGroupItem } from './RadioGroup.js'

export {
  ComboboxContent,
  ComboboxItem,
  Combobox,
  ComboboxInput,
  ComboboxTrigger,
  ComboboxList,
  ComboboxCreate
} from './Combobox.js'
